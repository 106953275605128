import React, { Component, CSSProperties } from 'react';
import './social-section.css';
import { Section } from '../../interfaces/section-interface';
import WindowSizeListener from 'react-window-size-listener'
import { getWindowWidth } from '../../utils/window';
import InteractiveIcon from '../interactive-icon/interactive-icon';

import Twitter from '../../images/twitter-icon.png';
import Facebook from '../../images/facebook-icon.png';
import Instagram from '../../images/instagram-icon.png';


export default class ContactInfoSection extends Component<Section, { iconStyle: CSSProperties }> {

    constructor(props: Section) {
        super(props);
        this.state = {
            iconStyle: this.getIconStyle(),
        }
    }

    render() {
        return (
            <div className="default-section-background social-section-background" id={this.props.id}>
                <div className="default-section social-section" >
                    <WindowSizeListener onResize={this.onWindowResized} />
                    <div className="social-item" >
                        <InteractiveIcon src={Twitter} iconStyle={this.state.iconStyle} href="https://twitter.com/Skirix_com" target="_blank" alt="Twitter" />
                    </div>
                    <div className="social-item" >
                        <InteractiveIcon src={Facebook} iconStyle={this.state.iconStyle} href="https://www.facebook.com/Skirix-355621925195508" target="_blank" alt="Facebook"/>
                    </div>
                    <div className="social-item" >
                        <InteractiveIcon src={Instagram} iconStyle={this.state.iconStyle} href="https://www.instagram.com/skirix_com" target="_blank" alt="Instagram"/>
                    </div>
                </div>
            </div >
        )
    }

    onWindowResized = () => {
        this.checkIconStyle();
    }

    checkIconStyle() {
        let newIconStyle = this.getIconStyle();
        if (this.state.iconStyle !== newIconStyle) {
            this.setState({ iconStyle: newIconStyle });
        }
    }

    getIconStyle() {
        if (getWindowWidth() <= 900) {
            return style.smalIcon;
        }
        return style.bigerIcon;
    }
}

const style: { [key: string]: CSSProperties } = {
    bigerIcon: {
        maxWidth: 35,
        maxHeight: 35
    },
    smalIcon: {
        maxWidth: 20,
        maxHeight: 20
    },
}