import React, { Component } from 'react';
import './contact-info-section.css';
import { Section } from '../../interfaces/section-interface';
import GPSArrow from '../../images/gps-arrow.png';
import Letter from '../../images/letter.png';
import Phone from '../../images/phone.png';
import ContactForm from '../../images/contact-form.png';
import InfoItem from '../../components/info-item/info-item';


export default class ContactInfoSection extends Component<Section, {}> {

    constructor(props: Section) {
        super(props);
    }

    render() {
        return (
            <div className="default-section-background contact-info-section-background" id={this.props.id}>
                <div className="default-section contact-info-section" >
                    <InfoItem icon={GPSArrow} title={"Location"} description={['Lerchenweg 3', React.createElement("br"), '34431 Marsberg']} />
                    <InfoItem icon={Letter} title={"E-Mail"} description={'info@skirix.com'} />
                    <InfoItem icon={Phone} title={"Mobil"} description={'+49 176 95827227'} />
                    <InfoItem icon={ContactForm} title={"Kontaktformular"} description={'Siehe oben'} />
                </div>
            </div >
        )
    }
}