import React, { Component } from 'react';
import LayoutBuilder from '../components/layout-builder/layout-builder';
import Header from '../components/header/header';

import ContactFormSection from '../components/contact-form-section/contact-form-section';
import ContactInfoSection from '../components/contact-info-section/contact-info-section';
import CertificatesSection from '../components/certificates-section/certificates-section';
import SocialSection from '../components/social-section/social-section';

const ContactSectionId = "contact-section";

export default class Contact extends Component<{}, {}> {

    render() {
        return (
            <LayoutBuilder>
                <Header showMap scrollToId={ContactSectionId} />
                <ContactFormSection id={ContactSectionId} />
                <ContactInfoSection />
                <CertificatesSection />
                <SocialSection />
            </LayoutBuilder>
        );
    }
}