import React, { Component } from 'react';
import './contact-form-section.css';
import { Section } from '../../interfaces/section-interface';
import { ReCaptcha } from 'react-recaptcha-google'
import Button from '../button/button';
import axios from 'axios';
import { validateEmail } from '../../utils/string-utils';
import LayoutBuilder from '../../components/layout-builder/layout-builder';

const API_PATH = '/api/contact.php';

interface ContactFormSectionState {
    name: string,
    email: string,
    message: string,
    termsChecked: boolean,
    company?: string,
    error?: string
    recaptchaToken?: string,
}

export default class ContactFormSection extends Component<Section, ContactFormSectionState> {

    private captchaDemo?: ReCaptcha;

    constructor(props: Section) {
        super(props);
        this.state = {
            name: "",
            email: "",
            message: "",
            termsChecked: false,
        }
        this.resetRecaptcha = this.resetRecaptcha.bind(this);
        this.verifyCallback = this.verifyCallback.bind(this);
    }

    componentDidMount() {
        if (this.captchaDemo) {
            this.captchaDemo.reset();
        }
    }

    render() {
        return (
            <div className="default-section-background" id={this.props.id}>
                <div className="default-section contact-form-section" >
                    <h1>Get In Touch</h1>
                    <p>
                        Gerne trete ich mit Ihnen in Kontakt und helfe Ihnen bei der Umsetzung Ihres Projektes. Sie können mich anrufen, mir eine E-Mail schreiben oder einfach die folgenden Felder aus füllen.
                        Gehen Sie dabei ruhig Detailliert vor und beschreiben Sie Ihre Vorstellung, Ihr Zeitlimit und Ihr Budget. Ihre Angaben werden Vertraulich behandelt und helfen dabei, Sie bestmöglich zu beraten.
                    </p>
                    <h3>Lassen Sie mich wissen, wie ich Ihnen helfen kann.</h3>
                    <form noValidate onSubmit={this.handleSubmit}>
                        <div className="input-fields-row" >
                            <div className="input-field-smal" >
                                Firma<br />
                                <input value={this.state.company} type="text" name="company" onChange={e => this.setState({ company: e.target.value })} />
                            </div>
                            <div className="input-field-smal" >
                                Name*<br />
                                <input required type="text" name="name" value={this.state.name} onChange={e => this.setState({ name: e.target.value })} />
                            </div>
                            <div className="input-field-smal" >
                                E-Mail*<br />
                                <input required type="email" name="email" value={this.state.email} onChange={e => this.setState({ email: e.target.value })} />
                            </div>
                        </div>
                        <div className="input-field-big" >
                            Nachricht*<br />
                            <textarea required name="message" value={this.state.message} onChange={e => this.setState({ message: e.target.value })} />
                        </div>
                        <div className="input-field-checkbox" >
                            <div className="input-field-checkbox-container">
                                <input required type="checkbox" name="terms" value="termscheck" checked={this.state.termsChecked} onChange={e => this.setState({ termsChecked: e.target.checked })} />
                            </div>
                            <p>
                                Ich stimme zu, dass meine Angaben aus dem Kontaktformular zur Beantwortung meiner Anfrage erhoben und
                                verarbeitet werden. Die Daten werden nach abgeschlossener Bearbeitung Ihrer Anfrage gelöscht.
                                Hinweis: Sie können Ihre Einwilligung jederzeit für die Zukunft per E-Mail an info@skirix.com widerrufen.
                                <br /><br />
                                Detaillierte Informationen zum Umgang mit Nutzerdaten finden Sie in unserer <a href="/privacy-policy">Datenschutzerklärung</a>.
                            </p>
                        </div>
                        <div className="grecaptcha-send" >
                            <ReCaptcha
                                ref={(el: ReCaptcha) => { this.captchaDemo = el; }}
                                size="normal"
                                data-theme="dark"
                                render="explicit"
                                sitekey="6Lfu96MUAAAAAHezUN7Vle_vwQucZdSMhLsczKrX"
                                onloadCallback={this.resetRecaptcha}
                                verifyCallback={this.verifyCallback}
                            />
                            <Button title="Senden" />
                        </div>
                        {this.state.error && <p className="form-error">{this.state.error}</p>}
                    </form>
                </div>
            </div >
        )
    }

    resetRecaptcha() {
        if (this.captchaDemo) {
            this.captchaDemo.reset();
        }
    }

    verifyCallback(recaptchaToken?: string) {
        this.setState({ recaptchaToken: recaptchaToken });
    }

    private handleSubmit = (event: any) => {
        event.preventDefault();
        if (!event.target.checkValidity() || !this.state.recaptchaToken || this.state.recaptchaToken.length == 0) {
            this.setState({ error: this.getErrorMessage() })
            if (!this.state.recaptchaToken || this.state.recaptchaToken.length == 0) {
                this.resetRecaptcha();
            }
            return;
        }
        this.setState({ error: undefined });
        this.sendContactMessage();
        this.resetRecaptcha();
    }

    private sendContactMessage = () => {
        let canncelled = false;
        LayoutBuilder.showLoader(SENDING_MESSAGE, undefined, () => { canncelled = true, LayoutBuilder.hideLoader() });
        const data = (({ name, email, company, message }) => ({ name, email, company, message }))(this.state);
        axios({
            method: 'post',
            url: API_PATH,
            headers: { 'content-type': 'application/json' },
            data: data,
        }).then(() => {
            if (!canncelled) {
                this.showResultInLoader(SEND_MESSAGE_SUCCESSFULLY);
                this.resetForm();
            }
        }).catch(error => {
            if (!canncelled) {
                this.showResultInLoader(SEND_MESSAGE_FAILD);
            }
        });
    }

    showResultInLoader(result: string) {
        LayoutBuilder.showLoader(result, false, () => {
            LayoutBuilder.hideLoader();
        }, "Ok");
    }

    private resetForm = () => {
        this.setState({ company: "", name: "", email: "", message: "", termsChecked: false, recaptchaToken: "" });
        this.resetRecaptcha();
    }

    private getErrorMessage: () => string = () => {
        if (!this.state.name || this.state.name.length == 0) {
            return NO_NAME_ERROR;
        } else if (!this.state.email || this.state.email.length == 0 || !validateEmail(this.state.email)) {
            return INVALID_EMAIL_ERROR;
        } else if (!this.state.message || this.state.message.length == 0) {
            return NO_MESSAGE_ERROR;
        } else if (!this.state.termsChecked) {
            return NO_ACCEPTED_TERMS;
        } else if (!this.state.recaptchaToken || this.state.recaptchaToken.length == 0) {
            return RECAPTCHA_NOT_ACCEPTED;
        }
        return DEFAULT_ERROR;
    }
}

const NO_NAME_ERROR = "Bitte gib einen Namen an.";
const INVALID_EMAIL_ERROR = "Bitte gib eine gültige E-Mail an.";
const NO_MESSAGE_ERROR = "Bitte gib eine Nachricht ein.";
const NO_ACCEPTED_TERMS = "Bitte akzeptiere die Verarbeitung deiner Daten.";
const RECAPTCHA_NOT_ACCEPTED = "Bitte bestätige das reCaptcha.";
const DEFAULT_ERROR = "Etwas ist schiefgelaufen, bitte probiere es noch ein mal.";
const SENDING_MESSAGE = "Nachricht wird versendet...";
const SEND_MESSAGE_SUCCESSFULLY = "Deine Nachricht wurde erfolgreich versendet.";
const SEND_MESSAGE_FAILD = "Deine Nachricht konnte nicht versendet werden, bitte probiere es noch ein mal.";
